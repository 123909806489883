import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="container">
        <div className="teaser">
            fluidlee, the right way to work
            <div className="learn-more">
          want to learn more? email us <a href="mailto:dror@fluidlee.com?subject=I want to learn more about fluidlee">dror@fluidlee.com</a>
        </div>
        </div>
    </div>
  );
}

export default App;
